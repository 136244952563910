import { FileExcelFilled } from "@ant-design/icons";
import { useApolloClient, useQuery } from "@apollo/client";
import { Button, Col, Empty, Form, Row, Table } from "antd";
import Search from "antd/lib/input/Search";
import { addDays, format, subDays } from "date-fns";
import React, { useEffect, useState } from "react";
import { Link, useMatch, useParams } from "react-router-dom";
import { PdfExportIcon } from "../assets/svg_icons";
import Error from "../components/apollo_error";
import DateRange from "../components/date_range";
import LoadingSpinner from "../components/loading_indicator";
import MultiSelect from "../components/multi_select";
import { export_orders, getExportOrderVar } from "../graphql/export";
import {
  getOrdersVar2,
  get_orders2,
  get_total_order_count,
} from "../graphql/order";
import { ExportExcel } from "../helpers/excel";
import { formatMoney } from "../helpers/utils";
import withClinic from "../hooks/with_clinic";
import paths from "../routes/paths";
import {
  ApolloResult,
  Clinic,
  Order,
  OrderExcel,
  OrderItemExcel,
} from "../store";

import useInvoiceTemplate from "../helpers/invoiceHelper";
import { PaginationConfig } from "antd/lib/pagination";
import { Typography } from "antd";
interface P {
  currentClinic?: Clinic;
}

const SalePage = (props: P) => {
  const clinicId = props.currentClinic?.id || "";
  const isSellerSales = useMatch(paths.seller_sales);
  const { id: sellerId } = useParams() as { id?: string };

  const client = useApolloClient();
  const [searchText, setSearchText] = useState<string | null>(null);
  const [filter, setFilter] = useState<string[]>([]);
  const [paymentFilter, SetPaymentFilter] = useState<string[]>([]);
  const [pagination, setPagination] = useState<PaginationConfig | null>(null);
  const [exporting, setExporting] = useState(false);
  const [ranges, setRanges] = useState({
    startDate: subDays(new Date(), 15),
    endDate: addDays(new Date(), 15),
  });
  const [form] = Form.useForm();
  const take = pagination?.pageSize || 20;
  const skip = ((pagination?.current || 1) - 1) * take;
  const variables = getOrdersVar2({
    clinicId,
    startDate: ranges.startDate,
    endDate: ranges.endDate,
    searchText,
    take,
    skip,
    status: filter,
    payment_status: paymentFilter,
    ...(isSellerSales && {
      sellerId: sellerId,
    }),
  });
  const { loading, data, error } = useQuery<ApolloResult<"orders", Order[]>>(
    get_orders2,
    { variables }
  );
  const totalCount = useQuery<
    ApolloResult<"aggregateOrder", { _count: { id: number } }>
  >(get_total_order_count, { variables });

  const { template, loading: invoiceLoading } = useInvoiceTemplate(
    props.currentClinic?.code || ""
  );

  if (loading || invoiceLoading) return <LoadingSpinner />;
  if (error) return <Error error={error} />;
  if (!data || !template) return <Empty />;
  const onDateChange = (startDate: Date, endDate: Date) => {
    setRanges({ startDate, endDate });
  };
  const handleExport = async () => {
    const fileName =
      "orders_" +
      format(ranges.startDate, "yyyy-MMM-dd") +
      "_" +
      format(ranges.endDate, "yyyy-MMM-dd");
    let excelData: any[] = [];
    const header = [
      "Order Date",
      "Order No",
      "Member",
      "Status",
      "Item Name",
      "Item Quantity",
      "Item Price",
      "Net Amount",
      "Total Amount",
      "Payment Made",
      "Balance Due",
      "Discount",
      "Tax",
      "Payment Status",
      "Payment",
      "Phone Number",
      "Seller",
      "Sold By",
      "Note",
    ];

    let list: Order[] = [];
    let rolling = true;
    const take = 500;
    let skip = 0;
    setExporting(true);
    while (rolling) {
      const variables = getExportOrderVar({
        clinicId,
        take,
        skip,
        startDate: ranges.startDate,
        endDate: ranges.endDate,
      });
      const result = await client.query<ApolloResult<"orders", OrderExcel[]>>({
        query: export_orders,
        variables,
      });
      const _orders = result.data?.orders || [];
      list = list.concat(_orders);
      skip += _orders.length;
      rolling = _orders.length === take;
    }
    for (const o of list) {
      const orderNo = o.order_id;
      const orderDate = format(new Date(o.created_at), "yyyy-MMM-dd");

      const netAmount = Number(o.net_total);
      const discount = Number(o.discount);
      const tax = Number(o.tax);
      const totalAmount = Number(o.total);

      const payment = o.payment_method;
      const member = o.member.name;
      const phone = o.member.phonenumber;
      const soldBy = o.user.name;
      const paymentMade = o.balance;
      const balanceDue = o.credit_balance;
      const paymentSuatus = o.payment_status;
      const seller = o.seller?.display_name;
      const status = o.status;
      const merchantNote = o.metadata
        ? JSON.parse(o.metadata).merchant_note
        : "";

      for (const item of o.order_items) {
        const itemName = getOrderItemName(item);
        const quantity = item.quantity;
        const price = item.price;
        excelData.push({
          orderDate,
          orderNo,
          member,
          status,
          itemName,
          quantity,
          price,
          netAmount,
          totalAmount,
          paymentMade,
          balanceDue,
          discount,
          tax,
          paymentSuatus,
          payment,
          phone,
          seller,
          soldBy,
          merchantNote,
        });
      }
    }
    ExportExcel(excelData, fileName, header);
    setExporting(false);
  };

  const getOrderItemName = (item: OrderItemExcel) => {
    if (item.product_stock_item) {
      return item.product_stock_item.name;
    }
    if (item.member_service_package) {
      return item.member_service_package.service_package.name;
    }
    if (item.service) {
      return item.service.name;
    }
    if (item.service_package) {
      return item.service_package.name;
    }
    return "";
  };

  const columns: any[] = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      editable: false,
      width: 170,
      ellipsis: true,
      sorter: (a: Order, b: Order) =>
        new Date(a.created_at).getTime() - new Date(b.created_at).getTime(),
      render: (v: Order, r: Order) => {
        return format(new Date(r.created_at), "dd MMM,yyyy h:mm a");
      },
    },
    {
      title: "OrderNo",
      dataIndex: "order_id",
      key: "order_id",
      editable: false,
      width: 110,
      ellipsis: true,
      sorter: (a: Order, b: Order) =>
        a.order_id.toLowerCase().localeCompare(b.order_id.toLowerCase()),
      render: (v: number, r: Order) => {
        return <Link to={paths.getSaleDetailRoute(r.id)}> {r.order_id}</Link>;
      },
    },
    {
      title: "Member",
      dataIndex: "member",
      key: "member",
      editable: false,
      width: 135,
      sorter: (a: Order, b: Order) =>
        a.member.name.toLowerCase().localeCompare(b.member.name.toLowerCase()),
      render: (v: number, r: Order) => {
        const val =
          r.member.clinic_members.length > 0
            ? r.member.clinic_members[0].name
            : r.member.name;
        return val;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 90,
      ellipsis: true,
      sorter: (a: Order, b: Order) => a.status.localeCompare(b.status),
      editable: false,
    },
    {
      title: "Total Amount",
      dataIndex: "total",
      key: "total",
      editable: true,
      width: 134,
      sorter: (a: Order, b: Order) => Number(a.total) - Number(b.total),
      render: (v: number, r: Order) => {
        return `${formatMoney(Number(r.net_total))} MMK`;
      },
    },
    {
      title: "Payment Made",
      dataIndex: "balance",
      key: "balance",
      editable: true,
      width: 143,
      sorter: (a: Order, b: Order) => Number(a.balance) - Number(b.balance),
      render: (v: number, r: Order) => {
        return `${formatMoney(Number(r.balance))} MMK`;
      },
    },
    {
      title: "Balance Due",
      dataIndex: "credit_balance",
      key: "credit_balance",
      width: 126,
      sorter: (a: Order, b: Order) =>
        Number(a.credit_balance) - Number(b.credit_balance),
      editable: true,
      render: (v: number, r: Order) => {
        return `${formatMoney(Number(r.credit_balance))} MMK`;
      },
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
      key: "payment_status",
      width: 145,
      ellipsis: true,
      sorter: (a: Order, b: Order) =>
        a.payment_method.localeCompare(b.payment_status),
      editable: true,
    },
    {
      title: "Payment",
      dataIndex: "payment_method",
      key: "payment_method",
      width: 103,
      ellipsis: true,
      sorter: (a: Order, b: Order) =>
        a.payment_method.localeCompare(b.payment_method),
      editable: true,
    },
    {
      title: "Seller",
      dataIndex: "seller",
      key: "seller",
      editable: false,
      width: 103,
      sorter: (a: Order, b: Order) =>
        a.seller?.display_name
          ?.toLowerCase()
          .localeCompare(b.seller?.display_name?.toLowerCase() ?? ""),
      render: (v: number, r: Order) => {
        return r.seller?.display_name;
      },
    },
    {
      title: "SoldBy",
      dataIndex: "user",
      key: "user",
      editable: false,
      width: 125,
      sorter: (a: Order, b: Order) =>
        a.user.name.toLowerCase().localeCompare(b.user.name.toLowerCase()),
      render: (v: number, r: Order) => {
        return r.user.name;
      },
    },
    {
      title: "Note",
      dataIndex: "metadata",
      key: "metadata",
      editable: false,
      width: 170,
      render: (v: number, r: Order) => {
        if (!r.metadata) return null;
        const metadata = JSON.parse(r.metadata);
        return (
          <Typography.Paragraph ellipsis={{ rows: 2, expandable: true }}>
            {metadata?.merchant_note}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      editable: false,
      fixed: "right",
      width: 74,
      render: (v: number, r: Order) => {
        return (
          <a
            rel="noreferrer"
            target="_blank"
            href={`https://greattime-api-core-hs6rtohe3q-uc.a.run.app/api/invoice?orderId=${r.id}&template=${template.template}&color=${template.color}`}
          >
            <PdfExportIcon />
          </a>
        );
      },
    },
    // https://greattime-api-core-hs6rtohe3q-uc.a.run.app/api/invoice?orderId=clp0tt75c0003s601qvv52bfr&template=template1
    // {
    //     title: 'Action',
    //     key: 'action',
    //     align: 'center',
    //     width: '10%',
    //     dataIndex: 'action',
    //     editable: false,
    //     fixed: 'right',
    //     render: (v: number, record: Account) => {
    //        // const editable = isEditing(record);
    //         return (
    //             <ButtonGroup>
    //                 {editable ?
    //                     (
    //                         saveLoading ? <Button type="primary" icon={<LoadingOutlined />} >saving...</Button> :
    //                             < Popconfirm placement="topRight" title={`Sure to save ${record.account_number}?`} onConfirm={() => handleSave(record)} onCancel={() => setEditingKey('')}>
    //                                 <Button type='primary' icon={<SaveOutlined />} >save</Button>
    //                             </Popconfirm>
    //                     )
    //                     :
    //                     <Button shape="circle" icon={<EditOutlined />}
    //                         onClick={() => edit(record)}
    //                     />
    //                 }
    //             </ButtonGroup >
    //         );

    //     }
    // }
  ];
  const control = (
    <Row style={{ marginTop: -18 }}>
      <Col flex={4}>
        <Row>
          <Col span={8}>Sales</Col>
          <Col span={8} offset={8}>
            <Search
              defaultValue={searchText || undefined}
              placeholder={`search ${totalCount.data?.aggregateOrder._count.id}...`}
              allowClear
              size="middle"
              onSearch={(val: any) => setSearchText(val)}
            />
          </Col>
        </Row>
      </Col>
      <Col flex={0}>
        <DateRange
          startDate={ranges.startDate}
          endDate={ranges.endDate}
          onSelect={onDateChange}
        />
      </Col>
    </Row>
  );
  const context = (
    <Row style={{ marginTop: 8 }}>
      <Col span={24}>
        <Form form={form} component={false}>
          <Table
            showHeader={data.orders.length > 0}
            dataSource={data.orders.map((b) => ({ key: b.id, ...b }))}
            columns={columns}
            rowClassName="editable-row"
            onChange={(p: PaginationConfig) => {
              setPagination(p);
            }}
            pagination={{
              total: totalCount.data?.aggregateOrder._count.id || 0,
              current: pagination?.current,
              pageSize: pagination?.pageSize,
              defaultPageSize: 20,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
          />
        </Form>
      </Col>
    </Row>
  );
  const filters = (
    <Row style={{ marginTop: 4, marginRight: 2 }}>
      <Col span={16}>
        <MultiSelect
          selected={filter}
          placeholder="Select orders status"
          options={["ACTIVE", "INACTIVE", "CANCEL", "DONE"]}
          onChange={setFilter}
        />
      </Col>
      <Col span={6}>
        <MultiSelect
          selected={paymentFilter}
          placeholder="Select orders Payment Status"
          options={["PAID", "UNPAID", "PARTIAL_PAID"]}
          onChange={SetPaymentFilter}
        />
      </Col>
      <Col span={2}>
        <Button
          size="small"
          loading={exporting}
          icon={<FileExcelFilled />}
          onClick={handleExport}
        >
          Export
        </Button>
      </Col>
    </Row>
  );

  return (
    <React.Fragment>
      {control}
      {filters}
      {context}
    </React.Fragment>
  );
};

export default withClinic(SalePage);
