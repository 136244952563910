import { useEffect, useState } from "react";
import {
  find_one_system_data,
  getOneSystemDataVar,
} from "../graphql/system_data";
import { Invoice } from "../store";
import { useQuery } from "@apollo/client";

interface UseInvoiceTemplateResult {
  template: Invoice | null;
  loading: boolean;
  error: Error | null;
}

const useInvoiceTemplate = (clinicCode: string): UseInvoiceTemplateResult => {
  const [template, setTemplate] = useState<Invoice | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const {
    loading,
    data,
    error: queryError,
  } = useQuery(find_one_system_data, {
    variables: getOneSystemDataVar("clinic_invoices"),
  });

  useEffect(() => {
    const processData = () => {
      try {
        if (data?.findUniqueSystemData?.metadata) {
          const invoices = JSON.parse(
            data.findUniqueSystemData.metadata
          ).invoices;
          let foundTemplate = invoices.find(
            (clinic: any) => clinic?.clinicCode === clinicCode
          );

          if (!foundTemplate) {
            foundTemplate = {
              clinicCode: clinicCode,
              template: "template1",
              color: "00ff00",
            };
          }

          setTemplate(foundTemplate);
        }
      } catch (err) {
        setError(
          err instanceof Error
            ? err
            : new Error("Failed to process invoice data")
        );
      }
    };

    if (!loading && !queryError) {
      processData();
    }

    if (queryError) {
      setError(queryError);
    }
  }, [data, loading, queryError, clinicCode]);

  return { template, loading, error };
};

export default useInvoiceTemplate;
